.button {
	color: white;
	background-color: $blue;
	padding: $s-size;
	font-size: $font-size-large;
	border: none;
	font-weight: 300;
	display: inline-block;
	text-decoration: none;
	line-height: 1;
	margin-top: 1rem;

	&--link {
		background: none;
	}

	&--secondary {
		background-color: $my-grey;
	}
}