// Colors
$dark-grey: #333333;
$blue: #1c88bf;
$dark-blue: #364051;
$my-grey: #888888;
$grey: rgb(113,128,150);
$off-white: #f7f7f7;
$light-grey: rgb(238, 242, 247);
$light-blue: rgb(108,232, 228);
$navy-blue: rgb(17, 22, 37);

// Font sizes
$font-size-large: 1.8rem;
$font-size-small: 1.4rem;

// Spacing
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;
$desktop-breakpoint: 45rem;
$max-size: 100rem;