.post {
	&__date {
		color: $grey;
		font-weight: 300;
	}
}

.post-preview {
	padding-bottom: 3rem;
	margin-bottom: 2rem;

	h3 {
		font-size: 3rem;
	}

	&__content {
		&:hover > * {
			color: darken($light-blue, 14%);
		}
	}
}