.tag {
	display: inline-block;
	background-color: darken($light-blue, 14%);
	padding: .5rem 1.5rem;
	text-transform: uppercase;
	color: white;
	font-size: 1.5rem;
	border-radius: 15rem;
	margin-right: 1rem;
	transition: 0.3s;
	margin-top: 1rem;
	margin-top: 1rem;
}

.tag:hover {
	color: white;
	background-color: darken($light-blue, 28%);
}