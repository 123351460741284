.text-bigger p {
	font-size: 2.5rem;
}

.border-bottom {
	border-bottom: 1px solid $light-grey;
}

img {
	width: 50%;
}