.show-for-mobile {
	@media (min-width: $desktop-breakpoint) {
		display: none;
	}
}

.show-for-desktop {
	@media (max-width: $desktop-breakpoint - .1rem) {
		display: none;
	}
}