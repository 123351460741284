* {
	box-sizing: border-box;
	margin: 0;
}

html {
  font-size: 62.5%;
}

body {
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif;
	font-size: 2rem;
	color: $dark-grey;
	line-height: 1.6;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

.is-active {
  font-weight: bold;
}

h1,h2,h3,h4,h5,h6 {
	padding: 0;
}

h1 {
	font-size: 6rem;
	color: rgb(33,24,60);
}

h3 {
	color: rgb(33,24,60);
}

a {
	color: blue;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
	text-decoration-color: blue;
}

p {
	font-size: 2rem;
}

.nostyle, .nostyle:hover {
	text-decoration: none;
	color: inherit;
}