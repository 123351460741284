.page-header {
	background: $light-grey;
	padding: $l-size 0;
	margin-bottom: $l-size;

	&__actions {
		margin-top: $m-size;
	}

	&__title {
		font-weight: 300;
		margin: 0;

		span {
			font-weight: 700;
		}
	}

	
}