.header {
	background: $dark-blue;
	z-index: 2;
	width: 100%;
	color: white;

	&__content {
		align-items: center;
		display: flex;
		justify-content: space-between;
		padding: $s-size 0;
	}

	&__title {
		color: white;
		text-decoration: none;
		font-size: 4rem;

		h1 {
			margin: 0;
		}
	}
}

.header-transparent {
	position: absolute;
	background: rgba(0,0,0,0);
}