@media (max-width: 45rem) {
	html {
		font-size: 50%;
	}
}

@media (max-width: 30rem) {
	html {
		font-size: 40%;
	}
}