.editor {
	width: 100%;
	background: $light-grey;
	
	@media (min-width: $desktop-breakpoint) {
		display: flex;
	}

	&__input {
		width: 50%;
		display: flex;
		flex-direction: column;

		@media (max-width: $desktop-breakpoint) {
			width: 100%;
		}
	}

	&__preview {
		width: 50%;
		word-break: break-all;

		@media (max-width: $desktop-breakpoint) {
			width: 100%;
		}
	}
}

.text-input {
	&--wide {
		width: 100%;
	}
}

.textarea {
	height: 100%;
	width: 100%;
}

// from https://codepen.io/zFunx/pen/XRyqvx
input[type="text"] {
  border: 1px solid $light-grey;
  border-radius: 1rem;
  margin: .8rem 0;
  outline: none;
  padding: .8rem;
  box-sizing: border-box;
	transition: 0.3s;
	font-size: 2rem;
}

input[type="text"]:focus {
  border-color: dodgerBlue;
  box-shadow: 0 0 .8rem 0 dodgerBlue;
}

.inputWithIcon input[type="text"] {
  padding-left: 4rem;
}

.inputWithIcon {
  position: relative;
}

.inputWithIcon img {
  position: absolute;
  left: 0;
  top: .8rem;
  padding: .9rem .8rem;
  color: #aaa;
	transition: 0.3s;
	font-size: 2.4rem;
	pointer-events: none;
	width: 4.5rem;
	height: 4.5rem;
}

.inputWithIcon input[type="text"]:focus + img {
  color: dodgerBlue;
}

.inputWithIcon.inputIconBg img {
  background-color: #aaa;
  color: #fff;
  padding: .9rem .4rem;
  border-radius: .4rem 0 0 .4rem;
}

.inputWithIcon.inputIconBg input[type="text"]:focus + img {
  color: #fff;
  background-color: dodgerBlue;
}
