.loader {
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background: $light-grey;
	text-align: center;

	&__content {
		background: white;
		padding: 6rem 8rem;
		border-radius: 1rem;
		-webkit-box-shadow: 0px 14px 19px -10px rgba(0,0,0,0.26);
		-moz-box-shadow: 0px 14px 19px -10px rgba(0,0,0,0.26);
		box-shadow: 0px 14px 19px -10px rgba(0,0,0,0.26);

		&--message {
			margin-bottom: 2rem;
		}
	}
}

@keyframes ldio-8w8am58tzjr {
  0% { transform: rotate(0deg) }
  50% { transform: rotate(180deg) }
  100% { transform: rotate(360deg) }
}
.ldio-8w8am58tzjr div {
  position: absolute;
  animation: ldio-8w8am58tzjr 1s linear infinite;
  width: 10rem;
  height: 10rem;
  top: 1.25rem;
  left: 1.25rem;
  border-radius: 50%;
  box-shadow: 0 4px 0 0 rgb(0, 136, 255);
  transform-origin: 5rem 5.125rem;
}
.loadingio-spinner-eclipse-osisb6eiupo {
  width: 12.5rem;
  height: 12.5rem;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
}
.ldio-8w8am58tzjr {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-8w8am58tzjr div { box-sizing: content-box; }
/* generated by https://loading.io/ */