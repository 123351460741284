.splash {
	position: relative;
	width: 100%;
	height: 100vh;
	max-height: 100vw;
	min-height: 50rem;
	background-color: rgb(34, 34, 71);
	// text-transform: uppercase;

	// * {
	// 	outline: 1px solid red;
	// }	

	&__head, &__body {
		position: relative;
		width: 100%;
		
  	&--content {
			padding: 2rem;

			h1 {
				color: white;
			}
			color: white;
		}
	}

	&__head {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		height: 60%;

		&--content {
			bottom: 0;
			max-width: $max-size;
			margin: 0 auto;
			width: 100%;
		}
	}

	.splash__title {
		font-size: 10rem;
		margin: 0;
		line-height: 10rem;
		font-weight: 300;

		&--bold {
			font-weight: 700;
		}

		&--sub {
			font-size: 9rem;
		}
	}

	&__body {
		background-color: $navy-blue;
		height: 40%;

		&--content {
			color: $navy-blue;
			margin: 0 auto;
			max-width: $max-size;
		}

		.fade--slogan {
			margin: 0;
			padding-left: 1rem;
			color: inherit;
			background: $light-blue;
			background: 
				linear-gradient(90deg, 
					$light-blue 0%, 
					rgba($navy-blue,0) 100%
				);
		}

		.fade--title {
			font-weight: 400;
			font-size: 5rem;
			margin: 0;
			color: $light-blue;
		}
	}
}

#coffee {
	position: relative;
	transition: all 500ms ease 0s;
	top: 0;
}


.splash__head--content:hover {
	#coffee {
		top: -.5rem;
	
		-webkit-transition: all 500ms cubic-bezier(0.165, 1, 0.560, 1); /* older webkit */
		-webkit-transition: all 500ms cubic-bezier(0.165, 1.275, 0.560, 1.650);
			-moz-transition: all 500ms cubic-bezier(0.165, 1.275, 0.560, 1.650);
				-o-transition: all 500ms cubic-bezier(0.165, 1.275, 0.560, 1.650);
						transition: all 500ms cubic-bezier(0.165, 1.275, 0.560, 1.650); /* custom */
	
		-webkit-transition-timing-function: cubic-bezier(0.165, 1, 0.560, 1); /* older webkit */
		-webkit-transition-timing-function: cubic-bezier(0.165, 1.275, 0.560, 1.650);
			-moz-transition-timing-function: cubic-bezier(0.165, 1.275, 0.560, 1.650);
				-o-transition-timing-function: cubic-bezier(0.165, 1.275, 0.560, 1.650);
						transition-timing-function: cubic-bezier(0.165, 1.275, 0.560, 1.650); /* custom */
	}
}
